<template>
  <button
    class="following-button"
    :class="{'btn-active': status}"
    :data-type="type"
    :disabled="loading"
    @click="$emit('handleFollowClick', status)"
  >
    <span class="loading-wrapper" v-if="loading">
      <span class="spinner-border text-primary" role="status"></span>
      <span class="fs-6 fw-bold ms-5">{{$t('Loading')}}...</span>
    </span>
    <span v-if="status">
      <i class="bi bi-check-lg"></i>
      {{ $t('Following') }}
    </span>
    <span v-else>{{ $t(title) }}</span>
  </button>
</template>

<script>
export default {
  name: "FollowingButton",
  props: {
    status: {
      type: Boolean,
      default: false
    },
    title: String,
    type: {
      type: String,
      default: ''
    },
    loading: Boolean
  },
  emits:["handleFollowClick"],
  methods:{
    handleFollowClick(){
      this.$emit('handle-invite')
    }
  },
}
</script>

<style scoped lang="scss">
.spinner-border {
  --bs-spinner-width: 20px;
  --bs-spinner-height: 20px;
}
.content-loader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 49px;
  background: #fff;
  border: 1px solid #435BF4;
  color: #435BF4;
}
.following-button {
  &[data-type="outline"] {
    background: transparent;
    border: 1px solid #435BF4;
    color: #435BF4;
  }
  position: relative;
  width: 100%;
  height: 39px;
  background: #435BF4;
  border: 1px solid #435BF4;
  border-radius: 49px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  line-height: 17px;
  &.btn-active {
    background: #435BF4;
    color: #fff;
    outline: 5px solid #B2BDFFA8;
  }

  &:disabled {
    border: none;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 19px;
      color: #fff;
      margin-right: 5px;
    }
  }
}
</style>

<template>
    <div class="platform-dashboard-pages">
        <div class="platform-dashboard-pages__filter" v-if="!filterLoading">
            <BankCustomersFilter
                ref="bankCustomersFilter"
                @applyFilters="applyFilters"
            />
        </div>

        <CustomersTable
            v-if="!loading"
            :customers="customers"
            @handleSearch="search = $event"
        />
    </div>

    <CustomLoader v-if="loading || filterLoading" height="200px" />
</template>

<script>
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import CustomersTable from "@/buying-teams/pages/bank/customers/components/CustomersTable";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import ScoreImportanceInfo from "@/buying-teams/shared-components/utils/ScoreImportanceInfo";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import BankCustomersFilter from "@/buying-teams/pages/bank/customers/sections/BankCustomersFilter";

export default {
    name: "Customers",
    components: {
        BankCustomersFilter,
        ScoreImportanceInfo,
        CustomLoader,
        CustomersTable,
    },
    data() {
        return {
            loading: true,
            filterLoading: true,
            search: '',
        };
    },
    computed: {
        customers() {
            return this.search
                ? store.getters.getBankCustomers.filter((item) => {
                    return item.business_name.toLowerCase().includes(this.search.toLowerCase())
                })
                : store.getters.getBankCustomers;
        },
        tiers() {
            return store.getters.tierList;
        },
        countries() {
            return store.getters.getBankFiltersState.customerList.countries;
        },
        industries() {
            return store.getters.getBankFiltersState.customerList.industries;
        },
        bankCustomersFilterData() {
            return store.getters.getBankCustomersFilterData;
        },
        bank() {
            return store.getters.bank;
        },
    },
    async created() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        await store.dispatch('initFiltersData', {type: BankFiltersTypeEnum.CUSTOMERS})
        this.filterLoading = false;

        this.fetchBankCustomers(this.bankCustomersFilterData);
    },
    methods: {
        applyFilters(filter) {
            this.fetchBankCustomers(filter)
        },
        fetchBankCustomers(filter = null) {
            this.loading = true;
            store.dispatch('fetchBankCustomers', filter).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<template>
    <div class="customers-table-wrapper">
        <div class="customers-table-wrapper__header">
            <div class="card-title">
                <h3 class="fw-bolder m-0 ">{{ $t("Customers") }}</h3>
                <span class="text-with-bg ms-3">{{ customers.length }}</span>
            </div>

            <div class="d-flex">
                <router-link :to="`/bank/customers/invite`" v-if="currentUser.can(BankUserPermissionTypeEnum.INVITE_CUSTOMERS)">
                    <div
                        size="small"
                        class="cursor-pointer"
                    >
                        <button class="btn main-btn">
                            {{$t("Invite Customers")}}
                        </button>
                    </div>
                </router-link>

                <div class="customers-table-wrapper__search">
                    <img src="/media/buying/icons/search.svg" alt="">
                    <el-input
                        v-model="search"
                        class="w-100"
                        size="large"
                        :placeholder="$t('Search Customer Name')"
                        @input="$emit('handleSearch', search)"
                    />
                </div>
            </div>
        </div>


        <el-table v-if="customers && customers.length" :data="customers" style="width: 100%">
            <el-table-column prop="logo" :label="$t('Logo')" width="86" align="center" header-align="center">
                <template #default="scope">
                    <el-avatar :src="scope.row.icon_path"/>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="business_name" sortable :label="$t('Name')">
                <template #default="scope">
                    <router-link :to="`/bank/customers/customer/${scope.row.id}`">
                        <div
                            size="small"
                            class="cursor-pointer"
                        >
                            {{ scope.row.business_name }}
                        </div>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column prop="tier" sortable :label="$t('Tier')" class-name="gray-semi-bold">
                <template #default="scope">
                    {{ scope.row.tier === 0 ? 'Unknown' : scope.row.tier }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip sortable prop="industries" :label="$t('Industries')"
                             class-name="gray-semi-bold"/>
            <el-table-column prop="business_hq" sortable :label="$t('Headquarters country')"
                             class-name="gray-semi-bold"/>
            <el-table-column prop="stats.score" sortable :label="$t('Score')" align="center" header-align="center">
                <template #default="scope">
                    <div class="follow-status" :class="getStatusClass(scope.row.stats.score)">
                        {{ numberDecimalsFormat(scope.row.stats.score) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="stats.importance" sortable :label="$t('Importance')" align="center"
                             class-name="color-gray">
                <template #default="scope">
                    <div class="follow-status">
                        {{ numberDecimalsFormat(scope.row.stats.importance) }}
                    </div>
                </template>
            </el-table-column>
            <!--      <el-table-column prop="followed" sortable :label="$t('Follow/Unfollow')" width="250" header-align="center">-->
            <!--        <template #default="scope">-->
            <!--          <div class="pe-3 customer-follow-table__followed">-->
            <!--            <FollowingButton :title="'Follow Customer'" type="outline" :status="scope.row.followed" @handle-follow-click="onFollowClick($event, scope.row.id)" />-->
            <!--          </div>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
        </el-table>
        <div v-else class="card">
            <div class="no-data-found text-center">{{ $t("No Data") }}</div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import FollowingButton from "@/buying-teams/shared-components/utils/FollowingButton";
import { getStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "CustomersTable",
    props: {
        customers: Array
    },
    components: {FollowingButton},
    data() {
        return {
            getStatusClass,
            numberDecimalsFormat,
            BankUserPermissionTypeEnum,
            search: '',
        };
    },
    methods: {
        onFollowClick(status, id) {
            this.customers.forEach(customer => {
                if (customer.id === id) {
                    customer.followed = !status;
                }
            });
        },
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        }
    }
};
</script>
<style lang="scss">
.customers-table-wrapper {
    border-radius: 17px;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    background: #fff;

    &__header {
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card-title {
            display: flex;
            align-items: center;
        }
    }

    .customer-follow-table__followed {
        margin: 5px 0;
    }
    .el-table{
        .color-gray {
            color: #8B8B8B;
            .cell {
                @extend .color-gray
            }
        }
        .gray-semi-bold {
            .cell {
                font-weight: 600;
                @extend .color-gray
            }
        }
        th.el-table__cell {
            background-color: #FAFAFA;
            height: 74px;
            .cell {
                color: #000000;
                font-weight: 400;
            }
        }
    }

    &__search {
        position: relative;
        margin-left: 10px;
        img {
            position: absolute;
            z-index: 1;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        .el-input__inner {
            background: #fff;
            height: 41px;
            padding-left: 47px;
            border-radius: 20px;
            border: 1px solid #e5e5e5;
            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #8B8B8B;
            }
        }
    }

    td {
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: #2B2B2B;
        }
    }

    .el-table th.el-table__cell .cell {
        white-space: pre;
    }

    .el-avatar {
        background: transparent;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media (max-width: 768px) {
        &__header {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
    }
}
</style>
